<template>
    <ul v-if="submenus.length > 0" class="dropdown-menu submenu">
        <li v-for="submenu in submenus" :key="submenu.id" :class="[(submenu.menu_item_hijas.length > 0 ? 'dropdown-submenu' : '')]">
            <a v-if="isExterno(submenu.url)" :href="submenu.url" class="dropdown-item" target="_blank">{{ submenu.nombre }}</a>
            <router-link v-else class="dropdown-item" :to="submenu.url">{{ submenu.nombre }}</router-link>                        

            <sub-menu
                v-if="submenu.menu_item_hijas.length > 0"
                :submenus="submenu.menu_item_hijas"
                :key="submenu.id"
            ></sub-menu>
        </li>                    
    </ul>        
</template>
<script>
import SubMenu from './SubMenu.vue';

export default {
    name:'SubMenu',
    components: {
        SubMenu
    },
    props: ['submenus'],    
    methods: {
        isExterno(url){
            if(url.indexOf('http') == 0)
                return true;
            else    
                return false;
        },
    },
};
</script>
<style scoped>

</style>