import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        envivo: 0,
        open_menu: 0,
        visible_menu: 0,
        config: {
          disenio: {
            datos:[]
          },
          sitio:{
            datos:[]
          },
          fiscaliatv: {
            datos:[]
          },
          videoftv:{
            datos:[]
          },
          redes_sociales: {
            datos:[]
          },
        },
    },
    mutations:{
        setEnvivo: function (state, val) {
          state.envivo = val;
        },
        setOpenMenu: function (state, val) {
          state.open_menu = val;
        },
        setVisibleMenu: function (state, val) {
          state.visible_menu = val;
        },
        setConfig: function(state, val){
          state.config = val;
        }
    }
})
