<template>
    <div>
        <ul class="menu-superior" >                            
            <!-- <li v-for="menu in menus" :key="menu.id">
                <router-link :to="menu.url">
                    <i class="icon" v-html="menu.icono"></i> <span>{{ menu.nombre }}</span>
                </router-link>         
            </li> -->
            <li v-if="envivo" class="me-3">
                <a href="/#envivo" class="envivo"> 
                    <i class="icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 4C16.2652 4 16.5196 4.10536 16.7071 4.29289C16.8946 4.48043 17 4.73478 17 5V9.2L22.213 5.55C22.288 5.49746 22.3759 5.4665 22.4672 5.4605C22.5586 5.4545 22.6498 5.4737 22.731 5.51599C22.8122 5.55829 22.8802 5.62206 22.9276 5.70035C22.9751 5.77865 23.0001 5.86846 23 5.96V18.04C23.0001 18.1315 22.9751 18.2214 22.9276 18.2996C22.8802 18.3779 22.8122 18.4417 22.731 18.484C22.6498 18.5263 22.5586 18.5455 22.4672 18.5395C22.3759 18.5335 22.288 18.5025 22.213 18.45L17 14.8V19C17 19.2652 16.8946 19.5196 16.7071 19.7071C16.5196 19.8946 16.2652 20 16 20H2C1.73478 20 1.48043 19.8946 1.29289 19.7071C1.10536 19.5196 1 19.2652 1 19V5C1 4.73478 1.10536 4.48043 1.29289 4.29289C1.48043 4.10536 1.73478 4 2 4H16ZM15 6H3V18H15V6ZM7.4 8.829C7.47611 8.82879 7.55069 8.8503 7.615 8.891L11.97 11.663C12.0266 11.6992 12.0731 11.749 12.1054 11.8079C12.1376 11.8668 12.1545 11.9329 12.1545 12C12.1545 12.0671 12.1376 12.1332 12.1054 12.1921C12.0731 12.251 12.0266 12.3008 11.97 12.337L7.615 15.11C7.55434 15.1487 7.48438 15.1703 7.41248 15.1725C7.34059 15.1748 7.26941 15.1576 7.20646 15.1228C7.14351 15.088 7.0911 15.0368 7.05477 14.9747C7.01844 14.9127 6.99951 14.8419 7 14.77V9.23C7 9.12391 7.04214 9.02217 7.11716 8.94716C7.19217 8.87214 7.29391 8.829 7.4 8.829ZM21 8.84L17 11.64V12.358L21 15.158V8.84Z" fill="white"/>
                        </svg>
                    </i>
                    <span>En vivo</span>
                </a>
            </li>
            <li class="me-2">
                <router-link to="/buscador">
                    <i class="icon"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.5 19.5C15.4706 19.5 19.5 15.4706 19.5 10.5C19.5 5.52944 15.4706 1.5 10.5 1.5C5.52944 1.5 1.5 5.52944 1.5 10.5C1.5 15.4706 5.52944 19.5 10.5 19.5Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M17.25 17.25L22.5 22.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </i>
                </router-link> 
            </li>            
            <li class="d-lg-none">
                <a @click="open_menu()" href="javascript:;" class="btn-menu-lateral" id="btn-menu-movil">
                    <i class="icon">
                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M23.3917 12.8333H4.60833C3.99622 12.8333 3.5 13.3295 3.5 13.9416V14.0583C3.5 14.6704 3.99622 15.1666 4.60833 15.1666H23.3917C24.0038 15.1666 24.5 14.6704 24.5 14.0583V13.9416C24.5 13.3295 24.0038 12.8333 23.3917 12.8333Z" fill="white"/>
                        <path d="M23.3917 18.6667H4.60833C3.99622 18.6667 3.5 19.1629 3.5 19.775V19.8917C3.5 20.5038 3.99622 21 4.60833 21H23.3917C24.0038 21 24.5 20.5038 24.5 19.8917V19.775C24.5 19.1629 24.0038 18.6667 23.3917 18.6667Z" fill="white"/>
                        <path d="M23.3917 7H4.60833C3.99622 7 3.5 7.49622 3.5 8.10833V8.225C3.5 8.83712 3.99622 9.33333 4.60833 9.33333H23.3917C24.0038 9.33333 24.5 8.83712 24.5 8.225V8.10833C24.5 7.49622 24.0038 7 23.3917 7Z" fill="white"/>
                        </svg>
                    </i>
                </a>                      
            </li>
        </ul>
        
    </div>
</template>
<script>
import axiosIns from '@/libs/axios';

export default {
  name: 'MenuSuperior',
  
  components:{

  },  
  data(){
    return{
        item:[],
        menus:[],        
    }
  },    
  mounted(){          
    //   this.listar();
  },  

  computed: {
      envivo(){
        //   console.log('var en vivo: ' + this.$store.state.envivo);
        return this.$store.state.envivo;
      }
  },
  methods: {
    listar(){
        var url= '/web/menu-superior';
        axiosIns.get(url)
        .then(res => {
            this.item = res.data; 
            this.menus = this.item.menuitems;            
        })
        .catch(err =>{
            console.log(err);
        });
    },
    open_menu(){
        this.$store.commit('setOpenMenu', 1);
        document.body.classList.toggle('nav-is-toggled');        
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  a.envivo {
      background: red;
      border-radius: 20px;
      padding: 2px 6px;
  }
  a.envivo span{
      margin-left: 5px;
  }

</style>