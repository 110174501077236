<template>
    <div>
        <div class="menu-overlay" @click="close_menu()"></div>
        <nav id="menu-lateral" class="nav-drill">
            <div class="menu-header">
            <a @click="close_menu()" href="javascript:;" class="btn-menu-lateral" id="btn-menu-close">
                <i class="icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 7.05L16.95 6L12 10.95L7.05 6L6 7.05L10.95 12L6 16.95L7.05 18L12 13.05L16.95 18L18 16.95L13.05 12L18 7.05Z" fill="#454859"/>
                </svg>
                </i>
            </a>
            </div>

            <ul class="nav-items nav-level-1" v-if="item_p || item">
                <li class="nav-item d-lg-none" v-for="menu in menus_p" :key="menu.id" :class="['submenu', (menu.menu_item_hijas.length > 0 ? 'nav-expand' : ''), (menu_open && menu.id==current_menu ? 'active': '')]">
                    <a 
                        v-if="menu.menu_item_hijas.length > 0 " 
                        href="javascript:void();" 
                        class="nav-link nav-expand-link" 
                        @click="open_submenu(menu.id)"
                        :id="'menu-' + menu.id" > 
                            {{ menu.nombre }}
                    </a>
                    <router-link 
                        v-else
                        :to="menu.url" 
                        class="nav-link"                         
                        :id="'menu-' + menu.id" > 
                        <span @click="close_menu()">{{ menu.nombre }}</span>
                    </router-link>

                    <!-- <ul v-if="menu.menu_item_hijas.length > 0" class="nav-items nav-expand-content">
                        <li class="nav-item">
                            <a class="nav-link nav-back-link" href="javascript:;" @click="close_submenu()">
                            Volver
                            </a>
                        </li>
                        <li class="nav-item" v-for="submenu in menu.menu_item_hijas" :key="submenu.id">
                            <a v-if="isExterno(submenu.url)" @click="close_menu()" :href="submenu.url" class="nav-link" target="_blank">{{ submenu.nombre }}</a>
                            <router-link 
                                v-else 
                                class="nav-link" 
                                :to="submenu.url">
                                <span @click="close_menu()">{{ submenu.nombre }}</span>
                            </router-link>                            
                        </li>                    
                    </ul> -->
                    <sub-menu-lateral
                        v-if="menu.menu_item_hijas.length > 0"
                        :submenus="menu.menu_item_hijas"
                        :key="menu.id"
                        @close_submenu="close_submenu($event)"
                    ></sub-menu-lateral>
                </li>

                <li class="nav-item" v-for="menu in menus" :key="menu.id">
                    <a 
                        v-if="menu.menu_item_hijas.length > 0" 
                        :href="'#'" 
                        class="nav-link nav-expand-link" 
                        :id="'menu-' + menu.id" > 
                            {{ menu.nombre }}
                    </a>
                    <router-link 
                        v-else
                        :to="menu.url" 
                        class="nav-link" 
                        :id="'menu-' + menu.id" > 
                        <span @click="close_menu()">{{ menu.nombre }}</span>
                    </router-link>

                    <!-- <ul v-if="menu.menu_item_hijas.length > 0" class="nav-items nav-expand-content">
                        <li class="nav-item">
                            <a class="nav-link nav-back-link" href="javascript:;" @click="close_submenu()">
                            Volver
                            </a>
                        </li>
                        <li class="nav-item" v-for="submenu in menu.menu_item_hijas" :key="submenu.id">
                            <a v-if="isExterno(submenu.url)" @click="close_menu()" :href="submenu.url" class="nav-link" target="_blank">{{ submenu.nombre }}</a>
                            <router-link 
                                v-else 
                                class="nav-link" 
                                @click.native="close_menu()" 
                                :to="submenu.url">
                                <span @click="close_menu()">{{ submenu.nombre }}</span>
                            </router-link>
                        </li>                    
                    </ul> -->
                    <sub-menu-lateral
                        v-if="menu.menu_item_hijas.length > 0"
                        :submenus="menu.menu_item_hijas"
                        :key="menu.id"
                        @close_submenu="close_submenu($event)"
                    ></sub-menu-lateral>
                </li>                
            </ul>
        </nav>        
    </div>
</template>
<script>
import axiosIns from '@/libs/axios';
import SubMenuLateral from './menu/SubMenuLateral.vue';

export default {
  name: 'MenuLateral',
  components:{
      SubMenuLateral,    
  },
  data(){
    return{
        item:[],
        menus:[],   
        item_p:[],
        menus_p:[],  
        
        current_menu:0,
        menu_open:false,
    }
  }, 
  computed: {
      openmenu(){
        return this.$store.state.open_menu;        
      },      
  }, 
  mounted(){          
    this.listar();    
  },
  created(){
    const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {      
      if(this.openmenu){
        next(false);
        this.close_menu();
      }else{
        next(true);
      }
    })

    this.$once('hook:destroyed', () => {
      unregisterRouterGuard()
    })  
  },
  methods: {    
    listar(){
        var url_p= '/web/menu-principal';
        axiosIns.get(url_p)
        .then(res => {
            this.item_p = res.data;
            this.menus_p = this.item_p.menuitems;        
        })
        .catch(err =>{
            console.log(err);
        });

        var url= '/web/menu-secundario';
        axiosIns.get(url)
        .then(res => {
            this.item = res.data; 
            this.menus = this.item.menuitems; 
            
            if(this.isMobile()){
                this.$store.commit('setVisibleMenu', 1);
            }else{
                if(this.item.menuitems.length > 0){
                    this.$store.commit('setVisibleMenu', 1);                
                }else{
                    this.$store.commit('setVisibleMenu', 0);
                }
            }
        })
        .catch(err =>{
            console.log(err);
        });
        
    },
    open_submenu(id_menu){
        this.menu_open = true;
        this.current_menu = id_menu;        
    },
    close_submenu(){
        this.menu_open = false;
        this.current_menu = 0;
    },
    close_menu(){
        this.close_submenu();
        document.body.classList.toggle('nav-is-toggled');        
        this.$store.commit('setOpenMenu', 0);
    },
    isExterno(url){
        if(url.indexOf('http') == 0)
            return true;
        else    
            return false;
    },
    isMobile() {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return true
        } else {
            return false
        }
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  
</style>