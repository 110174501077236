<template>
  <div>
    <header id="header-web" class="fixed-top" :class="{'scroll-header': scrollPosition > 50}">
      <div class="header-top">
        <div class="container">
          <router-link class="me-5" to="/">
            <img class="mt-2 mb-2 logo" :src="config.disenio.datos.logo_white">
          </router-link>
          <div class="menu-superior float-end">
            <!-- menu superior -->
            <menu-superior></menu-superior>
          </div>
        </div>
      </div>
      <nav id="header-topbar" class="navbar navbar-expand-md navbar-light">
        <div class="container">
          <div class="main-menu" id="navbarCollapse">
            <router-link class="navbar-brand" to="/">
              <img class="mt-2 mb-2 logo" :src="config.disenio.datos.logo_dark">
            </router-link>
            <!-- menu principal -->
            <menu-principal></menu-principal>
          </div>
        </div>
      </nav>
    </header>

    <menu-lateral></menu-lateral>
  </div>
</template>

<script>
import MenuSuperior from '../components/MenuSuperior.vue'
import MenuPrincipal from '../components/MenuPrincipal.vue'
import MenuLateral from '../components/MenuLateral.vue'

export default {
  name: 'HeaderWeb',
  data() {
    return {
      scrollPosition: null
    }
  },
  components: {
    MenuSuperior,
    MenuPrincipal,
    MenuLateral,
  },
  computed:{
    config(){
      return this.$store.state.config;
    },
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    }
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
