import Vue from 'vue'

import App from './App.vue'
import store from './store'
import router from './router'

import VueLazyload from 'vue-lazyload'
// import AOS from 'aos'
import NProgress from 'nprogress'

// import 'aos/dist/aos.css'
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css"

import BootstrapVue from "bootstrap-vue"
import "bootstrap-vue/dist/bootstrap-vue.css"

import '../node_modules/nprogress/nprogress.css'

import VueMeta from 'vue-meta'

require('/src/assets/css/style.css')
require('/src/assets/css/custom.css')
require('/src/assets/css/responsive.css')

Vue.use(BootstrapVue)
Vue.use(VueLazyload)

// Vue.use(AOS)
Vue.use(NProgress)
Vue.use(VueMeta, {refreshOnceOnNavigation: true})

// Global Components
import './global-components'

const loadimage = require('/src/assets/images/load.gif')
const errorimage = require('/src/assets/images/img-default.webp')

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: errorimage,
  loading: loadimage,
  attempt: 1,
  lazyComponent: true,
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  created () {
    //AOS.init()
  },
  render: h => h(App),
}).$mount('#app')
