<template>
    <div>
        <b-modal
            v-if="item"
            id="modal-popup"
            ref="modal-popup"
            header-bg-variant="light" 
            header-text-variant='dark'
            header-class="text-small"
            title-class='text-truncate'
            size="lg"
            body-class="p-0"            
            centered
            :hide-footer="true"
        >
            <template #modal-title>
              <h6>{{ item.titulo }}</h6>
            </template>
            <div v-if="item.medio">
                <div v-if="item.medio.tipo == 'VIDEO' || item.medio.tipo == 'DOCUMENTO' || item.medio.tipo == 'AUDIO'">
                  <b-embed 
                      type="iframe" 
                      :allowfullscreen="true" 
                      allow="autoplay; loop; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                      :src="item.medio.url"
                      class="ratio ratio-16x9"
                      controls   
                      autoplay
                      loop               
                  />
                </div>
                <div v-else-if="item.medio.tipo == 'IMAGEN'" class="text-center">
                  <img v-lazy="item.medio.url" class="img-fluid img-responsive" :alt="item.titulo" :title="item.titulo"> 
                </div>
            </div>            
            <div class="p-2" v-if="item.contenido">
                <div class="contenido-modal" v-html="item.contenido"></div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import axiosIns from "@/libs/axios";
import { BModal, VBModal, BEmbed } from "bootstrap-vue";

export default {
  components: {
    BModal,
    BEmbed,
  },
  directives: { 
        'b-modal': VBModal,
  },
  data() {
    return {      
      item:{
        medio:[]
      },
      url : process.env.VUE_APP_URL_SERVER,      
    };
  },
  created() {
    this.getActivePopup();
  },
  mounted() {
    
  },
  methods: {
    getActivePopup(){
      var url = '/web/popup';
        axiosIns.get(url)
        .then(res => {
            this.item = res.data;             
            if(this.item){
              this.$refs['modal-popup'].show();
            }         
        })
        .catch(err =>{
            console.log(err);
        });
    },
  },
}
</script>
<style scoped>
  .ratio, .embed-responsive{
    border-radius: 0px;    
  }
  
</style>
