import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { 
      path: '/', 
      name: 'inicio',
      component: () => import('@/views/Inicio.vue'),            
    },        
    {
      path: '/noticias',
      name: 'noticias',
      component: () => import('@/views/noticias/Index.vue'),
    },
    {
      path: '/noticia/:slug',
      name: 'noticia',
      component: () => import('@/views/noticias/Detalle.vue'),
    },     
    {
      path: '/pagina/:slug',
      name: 'pagina',
      component: () => import('@/views/Pagina.vue'),
    }, 
    {
      path: '/publicaciones/:tipo',
      name: 'publicaciones',
      component: () => import('@/views/publicaciones/Index.vue'),
    },    
    {
      path: '/publicaciones/:tipo/:slug',
      name: 'publicacion',
      component: () => import('@/views/publicaciones/Detalle.vue'),
    },
    {
      path: '/categoria/:slug',
      name: 'categoria',
      component: () => import('@/views/Categoria.vue'),
    }, 
    {
      path: '/videos',
      name: 'video',
      component: () => import('@/views/multimedia/Video.vue'),      
    }, 
    {
      path: '/videos/playlists',
      name: 'playlist',
      component: () => import('@/views/multimedia/Playlist.vue'),      
    }, 
    {
      path: '/videos/playlists/:slug',
      name: 'playlist-video',
      component: () => import('@/views/multimedia/PlaylistVideo.vue'),      
    }, 
    {
      path: '/fiscalia-tv',
      name: 'fiscalia-tv',
      component: () => import('@/views/multimedia/FiscaliaTv.vue'),      
    },  
    {
      path: '/transmisiones',
      name: 'transmision',
      component: () => import('@/views/multimedia/Transmision.vue'),      
    },  
    {
      path: '/podcasts',
      name: 'podcasts',
      component: () => import('@/views/multimedia/Podcast.vue'),      
    },   
    {
      path: '/fotos',
      name: 'galeria',
      component: () => import('@/views/multimedia/Galeria.vue'),      
    },   
    {
      path: '/biblioteca',
      name: 'biblioteca',
      component: () => import('@/views/biblioteca/Biblioteca.vue'),      
    },   
    {
      path: '/fiscalias',
      name: 'fiscalias',
      component: () => import('@/views/fiscalias/Index.vue'),
    },   
    {
      path: '/fiscalia/:slug',
      name: 'fiscalia',
      component: () => import('@/views/fiscalias/Detalle.vue'),
    },
    {
      path: '/eventos',
      name: 'eventos',
      component: () => import('@/views/eventos/Index.vue'),
    },   
    {
      path: '/evento/:slug',
      name: 'evento',
      component: () => import('@/views/eventos/Detalle.vue'),
    }, 
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),      
    },
    {
      path: '*',
      redirect: 'error-404',
    },
    {
      path: '/convocatorias',
      name: 'convocatorias',
      component: () => import('@/views/convocatorias/Index.vue'),
    },   
    {
      path: '/convocatoria/:slug',
      name: 'convocatoria',
      component: () => import('@/views/convocatorias/Detalle.vue'),
    },
    {
      path: '/rss',
      name: 'rss',
      component: () => import('@/views/Rss.vue'),
    },
    {
      path: '/buscador',
      name: 'buscador',
      component: () => import('@/views/Buscador.vue'),
    },
    {
      path: '/organigramas',
      name: 'organigramas',
      component: () => import('@/views/organigrama/Organigrama.vue'),
    },
    {
      path: '/estadisticas',
      name: 'estadisticas',
      component: () => import('@/views/estadisticas/Index.vue'),
    },
    {
      path: '/estadistica/:categoria',
      name: 'estadistica-categoria',
      component: () => import('@/views/estadisticas/ListaPorCategoria.vue'),
    },  
    {
      path: '/estadistica/:categoria/:slug',
      name: 'estadistica-categoria-publicacion',
      component: () => import('@/views/estadisticas/Detalle.vue'),
    },  
    {
      path: '/buzon-institucional',
      name: 'buzon',
      component: () => import('@/views/forms/BuzonInstitucional.vue'),
    }   
  ],
})

export default router
