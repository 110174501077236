import Vue from 'vue'
import axios from 'axios'
import NProgress from 'nprogress'

const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_URL_API,
  
  // baseURL: 'https://web-api-test.fiscalia.gob.bo/api',
  // baseURL: 'http://web-institucional-back.test/api',

  // headers: {'X-Custom-Header': 'fiscalia-web'},
  // headers: {'Access-Control-Allow-Origin': '*'},
  // headers: {'Access-Control-Allow-Origin': 'Content-Type'},
  withCredentials: true,
  xsrfCookieName: 'XSRF-TOKEN',
  xsrfHeaderName: 'X-XSRF-TOKEN',
  headers: {'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS'}
})

axiosIns.interceptors.request.use(config => {
  NProgress.start()
  return config
})

// before a response is returned stop nprogress
axiosIns.interceptors.response.use(response => {
  NProgress.done()
  return response
})

Vue.prototype.$http = axiosIns

export default axiosIns
