<template>
    <ul v-if="submenus.length > 0" class="nav-items nav-expand-content">
        <li class="nav-item">
            <a class="nav-link nav-back-link" href="javascript:;" @click="close_submenu()">
            Volver
            </a>
        </li>
        <li v-for="submenu in submenus" :key="submenu.id" :class="['nav-item submenu', (submenu.menu_item_hijas.length > 0 ? 'nav-expand' : ''), (menu_open && submenu.id==current_menu ? 'active': '')]">
            <a 
                v-if="submenu.menu_item_hijas.length > 0 " 
                href="javascript:void();" 
                class="nav-link nav-expand-link" 
                @click="open_submenu(submenu.id)"
                :id="'menu-' + submenu.id" > 
                    {{ submenu.nombre }}
            </a>
            <a v-else-if="isExterno(submenu.url)" @click="close_menu()" :href="submenu.url" class="nav-link" target="_blank">{{ submenu.nombre }}</a>
            <router-link
                v-else 
                class="nav-link" 
                :to="submenu.url">
                <span @click="close_menu()">{{ submenu.nombre }}</span>
            </router-link>
            <sub-menu-lateral
                v-if="submenu.menu_item_hijas.length > 0"
                :submenus="submenu.menu_item_hijas"
                :key="submenu.id"
                @close_submenu="close_submenu($event)"
            ></sub-menu-lateral>                            
        </li>                    
    </ul>
</template>
<script>
import SubMenuLateral from './SubMenuLateral.vue';

export default {
    name:'SubMenuLateral',
    components: {
        SubMenuLateral
    },
    props: ['submenus'],    
    data(){
        return{
            current_menu:0,
            menu_open:false,
        }
    }, 
    computed: {
        openmenu(){
            return this.$store.state.open_menu;        
        },      
    },
    created(){
        const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {      
        if(this.openmenu){
            next(false);
            this.close_menu();
        }else{
            next(true);
        }
        })

        this.$once('hook:destroyed', () => {
        unregisterRouterGuard()
        })  
    },
    methods: {
        isExterno(url){
            if(url.indexOf('http') == 0)
                return true;
            else    
                return false;
        },
        open_submenu(id_menu){
            this.menu_open = true;
            this.current_menu = id_menu;        
        },
        close_submenu(){
            this.menu_open = false;
            this.current_menu = 0;
            this.$emit('close_submenu');      
        },
        close_menu(){
            this.close_submenu();
            document.body.classList.toggle('nav-is-toggled');        
            this.$store.commit('setOpenMenu', 0);
        },
    },
};
</script>
<style scoped>

</style>